import React from 'react';

import Input from '../../../common2/Input';

function OptionalCarrierCaseIdInput({ claim, carriers, value, error, onChange }) {
  const carrierForThisShipment = carriers?.find(
    ({ code, countryCode }) => code === claim.carrierCode && countryCode === claim.carrierCountryCode
  );
  const isDisplayCarrierCaseIdInputEnabled = carrierForThisShipment?.isDisplayCarrierCaseIdInputEnabled;

  if (!isDisplayCarrierCaseIdInputEnabled) {
    return null;
  }

  return (
    <Input
      name="lmcCaseId"
      value={value}
      label="CARRIER_CASE_ID_LABEL"
      placeholder="Add carrier case ID"
      error={error}
      onChange={onChange}
    />
  );
}

export default OptionalCarrierCaseIdInput;
