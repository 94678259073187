import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { ApolloProvider } from '@apollo/client';

import ParcelFinderStartGuide from '../start-guides/ParcelFinderStartGuide';
import ParcelFinderPage from '../parcel-finder/ParcelFinderPage';
import ClaimsCenterPage from '../claims-center/ClaimsCenterPage';
import CarrierClaimPaymentsPage from '../claims-center/carrier-claim-payments/CarrierClaimPaymentsPage';
import withShopSettingsRedirect from '../../wrappers/withShopSettingsRedirect';
import ShipmentDetailsPage from '../parcel-finder/ShipmentDetailsPage/ShipmentDetailsPage';
import OrderDetailsPage from '../parcel-finder/OrderDetailsPage/OrderDetailsPage';
import { notificationsClient } from '../../notifications/graphql/notificationsClient';
import ClaimsCenterMaintenancePage from '../claims-center/ClaimsCenterMaintenancePage';
import { withMaintenanceRedirect } from '../../wrappers/withMaintenanceRedirect';
import PageLoader from '../PageLoader';

const withParcelFinderAccess = withShopSettingsRedirect('isClaimsEnabled', '/parcel-finder/start-guide');
const withClaimsCenterMaintenanceRedirect = withMaintenanceRedirect(
  'claims-maintenance',
  '/parcel-finder/claims-center/maintenance'
);
const ClaimsCenterPageWithAccess = withClaimsCenterMaintenanceRedirect(withParcelFinderAccess(ClaimsCenterPage));
const CarrierClaimPaymentsPageWithAccess = withClaimsCenterMaintenanceRedirect(
  withParcelFinderAccess(CarrierClaimPaymentsPage)
);

function ParcelFinderRoutes() {
  const { flagsReady } = useFlagsStatus();

  // Right now flags are used only within /parcel-finder routes and it's critical for routing to wait for them
  // If there will be more places with flags, we probably should consider moving this logic to a higher level
  // The downside is that higher level means the whole app might need to wait for flags being
  // loaded (which usually is very fast tho). For some places it can be even not that important to wait for flags.
  // context fields:
  // userId: shop id is set in AuthRoot
  if (!flagsReady) {
    return <PageLoader />;
  }

  return (
    <ApolloProvider client={notificationsClient}>
      <Routes>
        <Route index element={<ParcelFinderPage />} />
        <Route path="claims-center" element={<ClaimsCenterPageWithAccess />} />
        <Route path="carrier-claim-payments" element={<CarrierClaimPaymentsPageWithAccess />} />
        <Route path="claims-center/maintenance" element={<ClaimsCenterMaintenancePage />} />
        <Route path="start-guide" element={<ParcelFinderStartGuide />} />
        <Route path="shipments/:id" element={<ShipmentDetailsPage />} />
        <Route path="orders/:id" element={<OrderDetailsPage />} />
      </Routes>
    </ApolloProvider>
  );
}

export default ParcelFinderRoutes;
